<template>
  <aside>
    <header>
      <h2>End Nomination Campaign</h2>
      <button class="close" @click="close"><span class="sr-only">Close modal window</span></button>
    </header>

    <main v-if="campaign">
      <p>Announce the winners for <strong>{{campaign.title}}</strong> nominations. View the results below.</p>

      <div v-if="'users_nominated' in campaign" class="nominations">
        <div class="nomination-award" v-for="(nomination, index) in campaign.users_nominated" :key="`nomres_${index}`">
          <div class="award">
            <h4>{{nomination.award.name}}</h4>
          </div>
          <div class="results">
            <user-picker v-model="nomination.users" :can-add="false" v-if="nomination.users.length > 0">
              <template #afterName="{nomination_count}">
                <span class="nomination-count">{{$formatNumber(nomination_count)}}</span>
              </template>
            </user-picker>
            <span v-else>No nominations for this award.</span>
          </div>
        </div>
      </div>
      <loading-indicator v-else />

      <hr>
      <div class="setting-row">
        <div class="title">
          <h4>Custom message</h4>
          <p>Include a custom message with these awards.</p>
        </div>
        <div class="fields">
          <textarea rows="5" class="form-control" placeholder="(optional)" v-model="message"></textarea>
        </div>
      </div>
    </main>

    <footer>
      <button class="btn btn-sm btn-green" :class="{'loading': loading}" @click="endCampaign">End campaign</button>
    </footer>
  </aside>
</template>

<script>
import UserPicker from '@/components/UserPicker';

export default {
  props: ['data'],
  components: {
    UserPicker
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    }
  },
  data() {
    return {
      campaign: null,
      message: ''
    }
  },
  mounted() {
    this.campaign = _.cloneDeep(this.data)
    this.populateCampaign()
  },
  methods: {
    close() {
      this.$emit('done')
    },
    async populateCampaign() {
      const resp = await this.$api.Nominations.get_campaign(this.campaign.id)
      this.campaign = resp;
    },
    async endCampaign() {
      this.$store.commit('loading', true)
      const for_users = this.campaign.users_nominated.reduce( (obj, val) => {
        const users = _.map(val.users, 'id');
        const award = val.award;
        
        obj[award.id] = users;

        return obj;
      }, {})
      await this.$api.Nominations.end_campaign(this.campaign.id, for_users, this.message)
      this.$toast.success('Nomination campaign successfully ended.');
      this.$store.commit('loading', false)
      this.$emit('done', 'CAMPAIGN_ENDED');
    }
  },
}
</script>

<style lang="scss" scoped>
.nominations {
  margin: 35px 0 0;
  .nomination-award {
    display: flex;

    +.nomination-award {
      margin-top: 50px;
    }

    .award {
      display: block;
      flex: 0 0 30%;
      padding: 0 10px 0 0;

      > h4 {
        font-weight: 700;
        font-size: 14px;
        margin: 0 0 3px;
      }
    }

    .results {
      display: block; 
      flex: 1 0 0;
      padding: 0 15px 0 55px;
      min-width: 0;

      ::v-deep .user-picker {
        li {
          display: flex;
          align-items: center;
          > .nomination-count {
            display: block;
            background: darken($light, 10%);
            color: $muted-text;
            font-size: 10px;
            line-height: 1em;
            padding: 2px 5px;
            border-radius: 100em;
            font-weight:700;
            margin: 0 0 0 5px;
          }
        }
      }
    }
  }
}
</style>